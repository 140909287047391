<template>
  <div id="totem">
    <v-card color="#0F4A6D" height="100" class="hidden-md-and-up d-flex flex-row justify-space-between" tile elevation="0">
    </v-card>
    <v-row>
      <!-- Categories-->
      <v-col cols="3" >
        <v-card elevation="2"  height="1500" class="overflow-x-auto">
          <v-card elevation="0"
                  class="pa-5"
                  v-for="(category,index) in categories"
                  :key="index"
                  @click="() => categoryToShow = index "
          >
            <v-img
                class="ma-auto"
                max-width="200"
                src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
            ></v-img>
            <v-card-subtitle
                class="text-center"
            >{{category.name | text_capitalize}}</v-card-subtitle>
          </v-card>
        </v-card>
      </v-col>
      <!-- Products-->
      <v-col cols="9" class="px-10">
        <v-card elevation="0"  height="1500" class="overflow-y-auto overflow-x-hidden">
          <div class="text-h4 font-weight-bold ma-auto mt-4">{{getCatName}}</div>
          <div class="d-flex align-center flex-wrap">
            <v-col cols="5"
                   v-for="(item, index) in getList"
                   :key="index"
                   class="my-6 mr-4"
            >
              <menu-totem-item
                  :show_price="selectBebida"
                  :item="item"
                  :add-product="addProduct"
              ></menu-totem-item>
            </v-col>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-card color="#0F4A6D" height="50" class="hidden-md-and-up d-flex flex-row justify-space-between" tile elevation="0">
      <v-row class="white--text align-center justify-center">
        <v-col cols="3">
          <div class="text-center font-weight-bold">Mi carrito</div>
        </v-col>
        <v-col cols="9">
          <div class="text-center font-weight-bold">Mi orden</div>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="pa-4">
      <v-col cols="7">
        <cart-item @change="updateQuantity" v-for="item in cartItems" :key="item.id" :item="item" ></cart-item>
      </v-col>
      <v-col cols="5">
        <recipt-totem :cart-total="cartTotal" :wallet="wallet" :action="requestToPay" :cancel="gotToPreTotem"></recipt-totem>
      </v-col>
    </v-row>
    <processing-payment :have-error="errorPayment" :open="paymentModal" :show-message="paymentMessage"></processing-payment>
    <v-overlay v-show="newOrder" id="mobile_number">
      <div class="d-flex justify-center flex-column">
        <v-card class="white ma-5 pa-6" elevation="2">
          <v-card-subtitle class="black--text">Introduzca su número de teléfono móvil. Solo lo utilizaremos para avisarle cuando su pedido esté listo.</v-card-subtitle>
          <v-row>

            <v-text-field
                prepend-inner-icon="mdi-phone"
                autofocus
                outlined
                placeholder="+34xxxxxxxxx"
                @input="onPhoneInputChange"
                :value="cellphone"
                @focus="keyboardPhoneShow=true"
            ></v-text-field>
          </v-row>
          <v-card-actions>
            <v-btn block color="orange" flat @click="storePhone">Ingresar</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-overlay>
    <v-bottom-sheet hide-overlay inset v-model="keyboardPhoneShow" persistent>
      <numpad-keyboard
          @onChange="onPhoneChange" :input="cellphone"
      >
      </numpad-keyboard>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {Restaurant} from "@/services/api";
import MenuTotemItem from "@/components/restaurant/menuTotemItem";
import CartItem from "@/components/restaurant/CartItem";
import ReciptTotem from "@/components/restaurant/ReciptTotem";
import ProcessingPayment from "@/components/processingPayment";
import NumpadKeyboard from "@/components/numpadKeyborad";

export default {
  name: "DesktopMenu",
  components: {NumpadKeyboard, ProcessingPayment, ReciptTotem, CartItem, MenuTotemItem},
  data(){
    return{
      items: [],
      categories: [],
      categoryToShow: -1,
      wallet: 0,
      paymentModal: false,
      errorPayment: false,
      paymentMessage: 'Estamos procesando tu compra',
      petitionUrl: null,
      petitionXML: null,
      cellphone: null,
      loading: false,
      searching: false,
      newOrder: false,
      activityTimeout: null,
      keyboardPhoneShow : false,
      intervalChecker: 0,
      selectBebida: false,
      bebidasMenu: null,

    }
  },
  mounted() {
    if(!this.amphiEvent && !this.actualEvent){
      this.$router.push("/pretotem")
    }
    if(!this.$store.state.phoneNumber){
      this.newOrder = true
    }else {
      this.cellphone = this.$store.state.phoneNumber
      this.getViewData()
    }
    this.activityTimeout = setTimeout(this.gotToPreTotem, 180000);
    this.$el.addEventListener('keydown',this.checkActivity)
    this.$el.addEventListener('mousedown',this.checkActivity)
    this.$el.addEventListener('mousemove',this.checkActivity)
  },
  methods:{

    onPhoneChange(input) {
      this.cellphone = input;
    },
    onPhoneInputChange(value) {
      this.cellphone = value;
    },
    storePhone(){
      this.keyboardPhoneShow = false
      if(this.cellphone){
        let completePhone = this.cellphone
        this.searching = true
        this.$store.commit('setPhone', completePhone)
        this.newOrder = false
        this.getViewData()
      }else{
        console.log("nothing")
      }
      this.searching = false
    },
    requestRestaurant(){
      return Restaurant(this.user.token_type, this.user.access_token)
    },
    getViewData(){
      this.requestRestaurant().createOrder({
        phone_number: this.cellphone,
        amphi_reservation_id:  this.amphiEvent ? this.amphiEvent.id : null,
        totem: true
      })
          .then(
              restaurantOrder => {
                if(typeof restaurantOrder.data === "string"){
                  setTimeout(this.$router.push("/pretotem"),10000)
                  alert("Todavia no se habilito la compra, para el evento")
                }
                const order = restaurantOrder.data.data
                this.$store.commit('setOrder', order)
                this.$store.commit('setUser', {
                  token_type: 'Bearer',
                  access_token: order.access_token,
                })
                this.wallet = order.available_discount
                this.$store.commit('setLoading', false)
              }
          ).catch(e =>{
        console.log(e)
        alert(e.response.data)
        this.gotToPreTotem()
      })

      if(this.$store.state.articles.length > 0){
        this.items = this.$store.state.articles
        this.$store.commit('setLoading', false)
      }
      if(this.$store.state.categories.length > 0){
        this.categories = this.$store.state.categories
        this.$store.commit('setLoading', false)
      }

      let item =  this.requestRestaurant().getItems()
      let categories =  this.requestRestaurant().getCategory()

      // orderID
      return Promise.all([item,categories]).then(
          responses => {
            this.items = responses[0].data.data
            this.categories = responses[1].data.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            this.$store.commit('setItems', this.items)
            this.$store.commit('setCategories', this.categories)
            this.$store.commit('setLoading', false)
            console.log(this.categories)
            this.categoryToShow = 0
            console.log(this.categoryToShow)
          }
      )

    },
    addProduct(item){
      let item_related_id = null
      if(this.selectBebida){
        item_related_id = this.selectBebida
        this.selectBebida = false;
      }
      this.$store.commit('setLoading', true)
      this.requestRestaurant()
          .addArticle(
              this.$store.state.order.id,
              {
                'restaurant_article_id': item.id,
                'quantity': 1,
                'item_related_id':item_related_id
              }
          ).then(
          response =>{
            const arrayOfItems =  response.data.data.items
            const selectBebidaData =arrayOfItems.find(
                elem => elem.id === item.id
            )
            if(item.category.is_menu){
              this.selectBebida = selectBebidaData.order_item_id
              this.requestRestaurant()
                  .getDrinks(selectBebidaData.id)
                  .then(
                      drinksResponse => {
                        this.bebidasMenu = drinksResponse.data.articles
                        this.$store.commit('setOrder', response.data.data)
                        this.$store.commit('setLoading', false)
                      }
                  )
            }
            else {

              this.$store.commit('setOrder', response.data.data)
              this.$store.commit('setLoading', false)
            }
          }
      )
    },
    updateQuantity(data){

      this.$store.commit('setLoading', true)
      const restaurantRequest = this.requestRestaurant()
      const update_order = (response) =>{
        this.$store.commit('setOrder', response.data.data)
        this.$store.commit('setLoading', false)
      }
      restaurantRequest.updateArticle(data.id, data.quantity).then(update_order)

    },
    requestToPay(){
      let copyThis = this
      this.paymentModal = true
      const terminal = this.$store.state.terminalID;
      this.requestRestaurant()
          //tid-test = 90009914
          .PayOrder(terminal,true)
          .then( res => {
            console.log(res)
            if(res.status === 201){
              this.payed();
            }else if (res.status === 202){
              const ruid = res.data.ruid
              this.paymentMessage = 'Introduzca su tarjeta en la terminal'
              this.intervalChecker = setInterval(()=>{
                copyThis.checkPayment(ruid)
              },5000)
            }else if (res.status === 200){
              this.paymentMessage = 'Hubo un error, intentenuevamente'
              console.log(res.data.status_message)
            }
          })
          .catch(e=>{
            console.log(e);
            this.errorPayment = true
            this.paymentMessage = 'Ha ocurrido un error al generar el pedido'
          })
    },
    checkPayment(ruid){
      this.requestRestaurant()
          .checkIfPayed(ruid,this.$store.state.terminalID)
          .then(
              res => {
                if (res.status === 201) {
                  this.payed()
                  clearInterval(this.intervalChecker)
                }
              }
          );


    },
    payed(){
      this.paymentMessage = 'Se ha procesado su compra correctamente'
      setTimeout(this.gotToPreTotem,10000)
    },
    gotToPreTotem(){
      return this.$router.push("/pretotem")
    },
    checkActivity() {
      clearTimeout(this.activityTimeout);
      this.activityTimeout = setTimeout(this.gotToPreTotem, 180000);
    },

  },
  computed:{
    user(){
      return this.$store.state.user || {}
    },
    cartItems(){
      return this.$store.state.order ? this.$store.state.order.items :[]
    },
    getCatName(){
      console.log(this.categoryToShow)
      console.log(this.categories)
      return  this.categoryToShow === -1 ? '' : this.categories[this.categoryToShow].name
    },
    getList() {
      if(this.selectBebida){
        return this.items.filter(value => this.bebidasMenu.includes(value.bdp_code))
      } else if (this.categoryToShow === -1) {
        return []
      }else{
        return this.items.filter(
            item => {
              return  this.categories.length > 0 && item.category.id === this.categories[this.categoryToShow].id
            }
        ).sort((a,b)=>{
          return  a.name > b.name
        })
      }
    },
    cartTotal(){
      const items = this.$store.state.order ? this.$store.state.order.items : this.cartItems;
      let price = 0;
      items.forEach(item =>{
        price += item.quantity * item.unit_price
      })
      return price
    },
    amphiEvent(){
      return this.$store.state.reservation
    },
    actualEvent(){
      return this.$store.state.actualTotemEvent
    }
  },

}
</script>

<style lang="scss">
#totem{
  #mobile_number{
    .v-input{
      .v-input__slot{
        background: rgba(149, 149, 149, 0.47);
      }
    }
  }
}
</style>