<template>
  <div>
    <v-card elevation="0" @click="()=>addProduct(item)">
      <v-img
          class="ma-auto my-4"
          :lazy-src="item.image ? item.image.filepath : ''"
          :src="item.image ? item.image.filepath : ''"
          :alt="item.image ? item.image.name : ''"
      ></v-img>
      <v-row>
        <v-col cols="7">
          <div>
            {{ item.name | text_capitalize}}
          </div>
          <div class="text-subtitle-2 grey--text">
            {{ item.description }}
          </div>
        </v-col>
        <v-col cols="5" class="font-weight-bold  text-right">
          {{(!show_price ? item.unit_price: 0 )| money}}
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "menuTotemItem",
  props: ['item', "addProduct", "show_price"],
}
</script>

<style scoped>

</style>