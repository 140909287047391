<template>
  <div id="quantity-selector">
    <v-text-field
        :class="{'quantity':true,'small-input':small}"
        :value="computedValue"
        :readonly="true"
        outlined
        type="text"
    >
      <template v-slot:append>
        <v-icon
            :small="small"
            @click="addFood"
            :color="canAdd() ? 'orange':'default'"
        >mdi-plus</v-icon>
      </template>
      <template v-slot:prepend-inner>
        <v-icon
            :small="small"
            @click="subtractFood"
            :color="canSubtract() ? 'orange':'default'"
        >mdi-minus</v-icon>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "QuantitySelector",
  props: ['value', 'small'],
  computed: {
    computedValue(){
        return this.value
    }
  },
  methods:{
    canAdd(){
      return this.computedValue
    },
    canSubtract()
    {
      return this.computedValue > 1
    },
    addFood() {
      this.canAdd() ? this.$emit('input',this.computedValue+1) : null
    },
    subtractFood() {
      this.canSubtract() ? this.$emit('input',this.computedValue-1) : null
    }
  }
}
</script>

<style lang="scss">
#quantity-selector{
  max-height:30px;
  .quantity{
    &.small-input{
      fieldset, .v-text-field__slot, {
        max-height: 30px;
      }
      .v-input__slot {
        max-height: 30px;
        padding: 0 5px;
        .v-text-field__slot{
          border-left: 1px solid #9e9e9e;
          border-right: 1px solid #9e9e9e;
          height: 25px;
        }
        .v-input__append-inner, .v-input__prepend-inner {
          margin-top: 5px;
        }
      }

    }
    input{
      text-align: center;
      font-size: 14px;
    }
  }

}
</style>