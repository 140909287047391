<template>

  <div class="d-flex pa-2">
    <v-col cols="4">
      <v-img
          :lazy-src="item.image ? item.image.filepath : ''"
          :src="item.image ? item.image.filepath : ''"
          max-height="200"
          max-width="80"
          class="rounded"
      >
      </v-img>
    </v-col>

    <v-col cols="7" class="pa-2 ">
      <div style="font-size: 14px;" class="font-weight-bold">
        {{ item.name }}
      </div>
      <div  class="text-subtitle-2 grey--text d-flex align-center">
        <v-col cols="4">
          {{(!item.item_related_id ? item.unit_price: 0) | money}}
        </v-col>
        <v-col cols="7" class="pa-0 ml-auto">
          <quantity-selector v-if="!item.item_related_id" v-model="item.quantity" @input="updateData" :small="true"></quantity-selector>
        </v-col>

      </div>

    </v-col>
    <v-col cols="1" class="pa-0 ">
      <v-btn
          :disabled="item.item_related_id "
          @click="updateData(0)"
          icon
          x-small
          color="black">
        <v-icon>mdi-close</v-icon>

      </v-btn>
    </v-col>

  </div>
</template>

<script>
import QuantitySelector from "@/components/restaurant/QuantitySelector";
export default {
  name: "CartItem",
  components: {QuantitySelector},
  props: ['item'],
  methods:{
    updateData(data){
      this.$emit('change', {id:this.item.order_item_id, quantity:data})
    }
  }
}
</script>

<style scoped>

</style>