<template>
  <div class="text-center">
    <v-dialog
        v-model="open"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Pago
        </v-card-title>

        <v-card-text>
          <div class="text-center my-5">
            <v-progress-circular
                v-show="showMessage !== 'Se ha procesado su compra correctamente'"
                :width="3"
                color="primary"
                indeterminate
                class="text-center"
            ></v-progress-circular>

          </div>
          <v-spacer></v-spacer>
          <div class="text-center">{{showMessage}}</div>

        </v-card-text>

        <v-divider v-show="haveError"></v-divider>

        <v-card-actions v-show="haveError">
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="open = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "processingPayment",
  props: ['open','showMessage','haveError']
}
</script>

<style scoped>

</style>