<template>
  <v-card  outlined class="pa-2">
    <v-row class="px-1 py-1">
      <v-col cols="6">
        <v-card-subtitle class="font-weight-bold pa-0 ma-0">Pedido</v-card-subtitle>
        <v-card-text class="ma-0 pa-0">Total</v-card-text>
      </v-col>
      <v-col cols="6">
        <v-card-subtitle class="font-weight-bold text-right pa-0 ma-0">{{cartTotal | money}}</v-card-subtitle>
      </v-col>
    </v-row>
    <v-row class="px-1 py-1" v-if="wallet">
      <v-col cols="7">
        <v-card-subtitle class="font-weight-bold ma-0 pa-0">Saldo Disponible</v-card-subtitle>
        <v-card-text class="ma-0 pa-0">ID: </v-card-text>
      </v-col>
      <v-col col="5" class="text-p font-weight-bold text-right"> - {{wallet | money}}</v-col>
    </v-row>
    <v-card outlined style="background: #FFCA4433;">
      <v-row class="px-2 py-5">
        <v-col cols="6" class="text-h6 font-weight-bold">Total</v-col>
        <v-col col="6" class="text-h6 font-weight-bold text-right">{{cartTotal - wallet > 0 ? cartTotal - wallet: 0 | money}}</v-col>
      </v-row>
    </v-card>
    <v-card-actions>
      <v-btn block elevation="0" class="text-capitalize" color="orange" @click="action">
        Realizar Pago
      </v-btn>
    </v-card-actions>
    <v-card-actions>
      <v-btn block elevation="0" class="text-capitalize white--text" color="red" @click="cancel">
        Cancelar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ReciptTotem",
  props: ['cartTotal','wallet', 'action', 'cancel',]
}
</script>

<style scoped>

</style>